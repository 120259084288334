export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('auto-scroll', {
        beforeMount(el) {
            let interval = null;

            el._handleDragOver = function (event) {
                const { clientX } = event;
                const offset = 50; // Distance from edge to start scrolling

                // Clear any existing interval
                if (interval) {
                    clearInterval(interval);
                    interval = null;
                }

                // If the mouse is on the right edge of the screen, scroll right
                if (clientX > window.innerWidth - offset) {
                    //console.log('scroll right')
                    interval = setInterval(() => {
                        el.scrollLeft += 10;
                    }, 10);
                } else {
                    //console.log(clientX, window.innerWidth - offset)
                }
            };

            el.addEventListener('dragover', el._handleDragOver);

            el._handleDragLeave = function () {
                if (interval) {
                    clearInterval(interval);
                    interval = null;
                }
            };

            el.addEventListener('dragleave', el._handleDragLeave);
            el.addEventListener('drop', el._handleDragLeave); // Clear interval on drop
        },
        unmounted(el) {
            // Remove event listeners and clear interval when the element is unmounted
            if (el._handleDragOver) {
                el.removeEventListener('dragover', el._handleDragOver);
            }
            if (el._handleDragLeave) {
                el.removeEventListener('dragleave', el._handleDragLeave);
            }
            if (el._handleDrop) {
                el.removeEventListener('drop', el._handleDragLeave);
            }
        }
    });
});
