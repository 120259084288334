<template>
  <div v-if="!down" class="bg-surface-50 dark:bg-surface-950">
    <ConfirmPopup/>
    <Toast />
    <NuxtLoadingIndicator :height="3"/>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
  <div v-else>
    <div class="absolute-center-on-screen text-center">
      <div class="pi pi-wrench text-6xl"/>
      <h1 class="text-3xl">La plateforme est en maintenance</h1>
      <p class="text-lg mt-6">
        Nous travaillons fort pour vous offrir une meilleure expérience. Revenez
        plus tard.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
const token = useCookie("token");
const currentUser = useCurrentUser();

const down = ref(false);

if (token) {
  const { data } = await useFetch("/api/auth/me", {
    method: "GET",
  });
  currentUser.value = data.value;
}

// Ne pas enlever, ça cache les records
useRecordsFromTable("Régions", ["Nom"]);
useRecordsFromTable("Secteurs d'activité", ["Nom"]);
useRecordsFromTable("Champs d'intérêt", ["Nom"]);
</script>

<style>
html {
  overflow-y: scroll;
  /* font-size: 100%; */
  font-size: 14px;
  /* -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

body {
  margin: 0;
}

/* h3 a {
  text-decoration: none;
  color: #4b5563;
} */

.p-divider {
  height: 1px;
  background-color: #ffffff;
  opacity: 0.12;
}

.fit-content {
  height: fit-content;
}

.absolute-center-on-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-scrollpanel-wrapper {
  z-index: unset;
}

/** Animations */
.text-comedown-animation {
  animation: comeDown 0.75s ease-in-out;
}

/* .p-sidebar,
.p-overlaypanel,
.p-dialog {
  background: transparent;
  backdrop-filter: blur(20px);
  border-radius: 1.5rem;
} */

/* .p-sidebar-right .p-sidebar {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-dialog-header,
.p-dialog-content,
.p-dialog-footer {
  background: transparent;
} */

@keyframes comeDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.hover-moveup-animation {
  transition: transform 0.3s;
}

.hover-moveup-animation:hover {
  transform: translateY(-0.5rem);
}

.container {
  max-width: 1200px;
  padding: 0rem 1rem;
}

.break-word {
  word-wrap: break-word;
}

/** Layout transitions */
.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}

.layout-enter-from,
.layout-leave-to {
  opacity: 0;
  transform: translateX(5%)
}

.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  transform: translateY(0.25rem);
}
</style>
