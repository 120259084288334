import { default as editJNaEQqx5lFMeta } from "/vercel/path0/pages/account/bilan/edit.vue?macro=true";
import { default as availability9DlELMFdA4Meta } from "/vercel/path0/pages/account/buyer-profile/availability.vue?macro=true";
import { default as experiences_45skillsJhkFwBXTZWMeta } from "/vercel/path0/pages/account/buyer-profile/experiences-skills.vue?macro=true";
import { default as financing_45partnershipcgnwtJMmHVMeta } from "/vercel/path0/pages/account/buyer-profile/financing-partnership.vue?macro=true";
import { default as goalsTvRiqhcjsPMeta } from "/vercel/path0/pages/account/buyer-profile/goals.vue?macro=true";
import { default as indexhQkVfOnBjyMeta } from "/vercel/path0/pages/account/buyer-profile/index.vue?macro=true";
import { default as personal_45infosSPPNhojVdXMeta } from "/vercel/path0/pages/account/buyer-profile/personal-infos.vue?macro=true";
import { default as prioritiesvFjLunGlOyMeta } from "/vercel/path0/pages/account/buyer-profile/priorities.vue?macro=true";
import { default as search_45criteriasJPph9tWKXYMeta } from "/vercel/path0/pages/account/buyer-profile/search-criterias.vue?macro=true";
import { default as summaryKchKtgjibJMeta } from "/vercel/path0/pages/account/buyer-profile/summary.vue?macro=true";
import { default as favouritesQO79dk2nzmMeta } from "/vercel/path0/pages/account/favourites.vue?macro=true";
import { default as editc1ku5gb4ntMeta } from "/vercel/path0/pages/account/fiche/edit.vue?macro=true";
import { default as infos6c2uxg7aNRMeta } from "/vercel/path0/pages/account/infos.vue?macro=true";
import { default as financesC6B80zEuVtMeta } from "/vercel/path0/pages/acquisitionFiles/[id]/finances.vue?macro=true";
import { default as history7Sq2zjoVjvMeta } from "/vercel/path0/pages/acquisitionFiles/[id]/history.vue?macro=true";
import { default as indexhmMJrq17iwMeta } from "/vercel/path0/pages/acquisitionFiles/[id]/index.vue?macro=true";
import { default as projectp0sJ7RaWpqMeta } from "/vercel/path0/pages/acquisitionFiles/[id]/project.vue?macro=true";
import { default as settings1vvh67gnJUMeta } from "/vercel/path0/pages/acquisitionFiles/[id]/settings.vue?macro=true";
import { default as indexWgKWhzAJGRMeta } from "/vercel/path0/pages/acquisitionFiles/index.vue?macro=true";
import { default as indexWPDTLxl5uEMeta } from "/vercel/path0/pages/acquisitionFiles/new/index.vue?macro=true";
import { default as clients0dt8FtPAa0Meta } from "/vercel/path0/pages/admin/clients.vue?macro=true";
import { default as logbookIpofIFYBqHMeta } from "/vercel/path0/pages/admin/logbook.vue?macro=true";
import { default as indexVslVie2HsrMeta } from "/vercel/path0/pages/admin/users/[id]/index.vue?macro=true";
import { default as indexgxZ1ikbUBwMeta } from "/vercel/path0/pages/admin/users/index.vue?macro=true";
import { default as index0dWHxwv6MkMeta } from "/vercel/path0/pages/analytics/index.vue?macro=true";
import { default as indexXx2W3Zhi7PMeta } from "/vercel/path0/pages/curriculum/index.vue?macro=true";
import { default as indexus0p40GBRPMeta } from "/vercel/path0/pages/entreprises/index.vue?macro=true";
import { default as indexvc0UKbqdirMeta } from "/vercel/path0/pages/formations/[formationId]/index.vue?macro=true";
import { default as _91id_93YC2G5xIRsNMeta } from "/vercel/path0/pages/formations/[formationId]/modules/[id].vue?macro=true";
import { default as indexFnQUfUDAZPMeta } from "/vercel/path0/pages/formations/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93W5u0AO8tV1Meta } from "/vercel/path0/pages/listings/[id].vue?macro=true";
import { default as indexN2jJ9vdJFFMeta } from "/vercel/path0/pages/listings/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as _91token_93dUxLEfZM55Meta } from "/vercel/path0/pages/magicLink/[token].vue?macro=true";
import { default as outilsgf1n3zNzVqMeta } from "/vercel/path0/pages/outils.vue?macro=true";
import { default as _91id_93EHFJp2Lrb5Meta } from "/vercel/path0/pages/pipelines/[id].vue?macro=true";
import { default as projectscSYnWgH6LrMeta } from "/vercel/path0/pages/projects.vue?macro=true";
import { default as prospectionp11nWCgLp9Meta } from "/vercel/path0/pages/prospection.vue?macro=true";
import { default as _91id_93KdPf7DV5TJMeta } from "/vercel/path0/pages/sign/[id].vue?macro=true";
import { default as contactYmygUw6xWcMeta } from "/vercel/path0/pages/vendre/contact.vue?macro=true";
import { default as index40sfUVdTe1Meta } from "/vercel/path0/pages/vendre/index.vue?macro=true";
export default [
  {
    name: "account-bilan-edit",
    path: "/account/bilan/edit",
    component: () => import("/vercel/path0/pages/account/bilan/edit.vue")
  },
  {
    name: "account-buyer-profile-availability",
    path: "/account/buyer-profile/availability",
    meta: availability9DlELMFdA4Meta || {},
    component: () => import("/vercel/path0/pages/account/buyer-profile/availability.vue")
  },
  {
    name: "account-buyer-profile-experiences-skills",
    path: "/account/buyer-profile/experiences-skills",
    meta: experiences_45skillsJhkFwBXTZWMeta || {},
    component: () => import("/vercel/path0/pages/account/buyer-profile/experiences-skills.vue")
  },
  {
    name: "account-buyer-profile-financing-partnership",
    path: "/account/buyer-profile/financing-partnership",
    meta: financing_45partnershipcgnwtJMmHVMeta || {},
    component: () => import("/vercel/path0/pages/account/buyer-profile/financing-partnership.vue")
  },
  {
    name: "account-buyer-profile-goals",
    path: "/account/buyer-profile/goals",
    meta: goalsTvRiqhcjsPMeta || {},
    component: () => import("/vercel/path0/pages/account/buyer-profile/goals.vue")
  },
  {
    name: "account-buyer-profile",
    path: "/account/buyer-profile",
    meta: indexhQkVfOnBjyMeta || {},
    component: () => import("/vercel/path0/pages/account/buyer-profile/index.vue")
  },
  {
    name: "account-buyer-profile-personal-infos",
    path: "/account/buyer-profile/personal-infos",
    meta: personal_45infosSPPNhojVdXMeta || {},
    component: () => import("/vercel/path0/pages/account/buyer-profile/personal-infos.vue")
  },
  {
    name: "account-buyer-profile-priorities",
    path: "/account/buyer-profile/priorities",
    meta: prioritiesvFjLunGlOyMeta || {},
    component: () => import("/vercel/path0/pages/account/buyer-profile/priorities.vue")
  },
  {
    name: "account-buyer-profile-search-criterias",
    path: "/account/buyer-profile/search-criterias",
    meta: search_45criteriasJPph9tWKXYMeta || {},
    component: () => import("/vercel/path0/pages/account/buyer-profile/search-criterias.vue")
  },
  {
    name: "account-buyer-profile-summary",
    path: "/account/buyer-profile/summary",
    meta: summaryKchKtgjibJMeta || {},
    component: () => import("/vercel/path0/pages/account/buyer-profile/summary.vue")
  },
  {
    name: "account-favourites",
    path: "/account/favourites",
    component: () => import("/vercel/path0/pages/account/favourites.vue")
  },
  {
    name: "account-fiche-edit",
    path: "/account/fiche/edit",
    component: () => import("/vercel/path0/pages/account/fiche/edit.vue")
  },
  {
    name: "account-infos",
    path: "/account/infos",
    component: () => import("/vercel/path0/pages/account/infos.vue")
  },
  {
    name: "acquisitionFiles-id-finances",
    path: "/acquisitionFiles/:id()/finances",
    meta: financesC6B80zEuVtMeta || {},
    component: () => import("/vercel/path0/pages/acquisitionFiles/[id]/finances.vue")
  },
  {
    name: "acquisitionFiles-id-history",
    path: "/acquisitionFiles/:id()/history",
    meta: history7Sq2zjoVjvMeta || {},
    component: () => import("/vercel/path0/pages/acquisitionFiles/[id]/history.vue")
  },
  {
    name: "acquisitionFiles-id",
    path: "/acquisitionFiles/:id()",
    meta: indexhmMJrq17iwMeta || {},
    component: () => import("/vercel/path0/pages/acquisitionFiles/[id]/index.vue")
  },
  {
    name: "acquisitionFiles-id-project",
    path: "/acquisitionFiles/:id()/project",
    meta: projectp0sJ7RaWpqMeta || {},
    component: () => import("/vercel/path0/pages/acquisitionFiles/[id]/project.vue")
  },
  {
    name: "acquisitionFiles-id-settings",
    path: "/acquisitionFiles/:id()/settings",
    meta: settings1vvh67gnJUMeta || {},
    component: () => import("/vercel/path0/pages/acquisitionFiles/[id]/settings.vue")
  },
  {
    name: "acquisitionFiles",
    path: "/acquisitionFiles",
    component: () => import("/vercel/path0/pages/acquisitionFiles/index.vue")
  },
  {
    name: "acquisitionFiles-new",
    path: "/acquisitionFiles/new",
    component: () => import("/vercel/path0/pages/acquisitionFiles/new/index.vue")
  },
  {
    name: "admin-clients",
    path: "/admin/clients",
    component: () => import("/vercel/path0/pages/admin/clients.vue")
  },
  {
    name: "admin-logbook",
    path: "/admin/logbook",
    component: () => import("/vercel/path0/pages/admin/logbook.vue")
  },
  {
    name: "admin-users-id",
    path: "/admin/users/:id()",
    component: () => import("/vercel/path0/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/vercel/path0/pages/admin/users/index.vue")
  },
  {
    name: "analytics",
    path: "/analytics",
    component: () => import("/vercel/path0/pages/analytics/index.vue")
  },
  {
    name: "curriculum",
    path: "/curriculum",
    component: () => import("/vercel/path0/pages/curriculum/index.vue")
  },
  {
    name: "entreprises",
    path: "/entreprises",
    component: () => import("/vercel/path0/pages/entreprises/index.vue")
  },
  {
    name: "formations-formationId",
    path: "/formations/:formationId()",
    component: () => import("/vercel/path0/pages/formations/[formationId]/index.vue")
  },
  {
    name: "formations-formationId-modules-id",
    path: "/formations/:formationId()/modules/:id()",
    component: () => import("/vercel/path0/pages/formations/[formationId]/modules/[id].vue")
  },
  {
    name: "formations",
    path: "/formations",
    component: () => import("/vercel/path0/pages/formations/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "listings-id",
    path: "/listings/:id()",
    component: () => import("/vercel/path0/pages/listings/[id].vue")
  },
  {
    name: "listings",
    path: "/listings",
    component: () => import("/vercel/path0/pages/listings/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "magicLink-token",
    path: "/magicLink/:token()",
    component: () => import("/vercel/path0/pages/magicLink/[token].vue")
  },
  {
    name: "outils",
    path: "/outils",
    component: () => import("/vercel/path0/pages/outils.vue")
  },
  {
    name: "pipelines-id",
    path: "/pipelines/:id()",
    component: () => import("/vercel/path0/pages/pipelines/[id].vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/vercel/path0/pages/projects.vue")
  },
  {
    name: "prospection",
    path: "/prospection",
    meta: prospectionp11nWCgLp9Meta || {},
    component: () => import("/vercel/path0/pages/prospection.vue")
  },
  {
    name: "sign-id",
    path: "/sign/:id()",
    meta: _91id_93KdPf7DV5TJMeta || {},
    component: () => import("/vercel/path0/pages/sign/[id].vue")
  },
  {
    name: "vendre-contact",
    path: "/vendre/contact",
    component: () => import("/vercel/path0/pages/vendre/contact.vue")
  },
  {
    name: "vendre",
    path: "/vendre",
    component: () => import("/vercel/path0/pages/vendre/index.vue")
  }
]