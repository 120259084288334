export const useRecordsFromTable = async (
  table: string,
  fieldsToReturn: string[]
) => {
  const cachedRecords = useState("cachedRecords", () => {
    return {}; // id as the key, record as the value
  });
  const { data } = await useFetch(
    `/api/airtable/recordsFromTable?table=${table}&fieldsToReturn=${fieldsToReturn?.join(
      ","
    )}`
  );

  // cache the records
  data?.value?.records.forEach((record) => {
    record['_table'] = table;
    cachedRecords.value[record?.id] = record;
  });

  return useState(table, () => {
    return data?.value?.records;
  });
};
