import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/vercel/path0/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/vercel/path0/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/vercel/path0/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import clickOutside_client_YeoeLf0aWu from "/vercel/path0/plugins/directives/clickOutside.client.ts";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
import autoScroll_client_em46nOeTJc from "/vercel/path0/plugins/autoScroll.client.ts";
import draggable_client_hyqucl8vfz from "/vercel/path0/plugins/draggable.client.ts";
import fullcalendar_client_qAcKSSkGJk from "/vercel/path0/plugins/fullcalendar.client.ts";
import loading_GxQ2Z9Goxa from "/vercel/path0/plugins/loading.ts";
import toast_ysMjUcU7eJ from "/vercel/path0/plugins/toast.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  clickOutside_client_YeoeLf0aWu,
  analytics_client_mtqVw3YRXk,
  autoScroll_client_em46nOeTJc,
  draggable_client_hyqucl8vfz,
  fullcalendar_client_qAcKSSkGJk,
  loading_GxQ2Z9Goxa,
  toast_ysMjUcU7eJ
]