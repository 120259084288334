export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("click-outside", {
    mounted(el, binding) {
      const setClickOutsideEvent = () => {
        const mainContent = document.getElementById("main-content");

        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event);
          }
        };
        if (mainContent) {
          mainContent.addEventListener("click", el.clickOutsideEvent);
        } else {
          document.body.addEventListener("click", el.clickOutsideEvent);
        }
      };

      // Check if document is already loaded
      if (document.readyState === "loading") {
        // Wait for the DOM to be fully loaded
        document.addEventListener("DOMContentLoaded", setClickOutsideEvent);
      } else {
        // DOM is already loaded
        setClickOutsideEvent();
      }
    },
    unmounted(el) {
      const mainContent = document.getElementById("main-content");
      if (mainContent) {
        mainContent.removeEventListener("click", el.clickOutsideEvent);
      } else {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      }
    },
  });
});
